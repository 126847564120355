import React from 'react'

export default function Merkle() {
  return (
    <>
      <h2>Coming soon</h2>
      <p>NFT Airdrops</p>
    </>
  )
}
