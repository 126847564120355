import { SupportedChainId } from './chains'
const UNI_LIST = 'https://tokens.uniswap.org'

const AAVE_LIST = 'tokenlist.aave.eth'
const BA_LIST = 'https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json'
const CMC_ALL_LIST = 'https://api.coinmarketcap.com/data-api/v3/uniswap/all.json'
const COINGECKO_LIST = 'https://tokens.coingecko.com/uniswap/all.json'
const COMPOUND_LIST = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
const GEMINI_LIST = 'https://www.gemini.com/uniswap/manifest.json'
export const ARBITRUM_LIST = 'https://bridge.arbitrum.io/token-list-42161.json'
const KLEROS_LIST = 't2crtokens.eth'
export const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'
const ROLL_LIST = 'https://app.tryroll.com/tokens.json'
const SET_LIST = 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.tokenlist.json'
const WRAPPED_LIST = 'wrapped.tokensoft.eth'
export const METIS_LIST = 'https://raw.githubusercontent.com/Netswap/token-lists/master/mb.tokenlist.json'
export const FUSE_TOKEN_LIST =
  'https://raw.githubusercontent.com/voltfinance/swap-default-token-list/master/build/voltage-swap-default.tokenlist.json'

export const NETWORK_SPECIFIC_LISTS: { [chainId: number]: string } = {
  [SupportedChainId.MAINNET]: UNI_LIST,
  [SupportedChainId.FUSE_MAINNET]:
    'https://raw.githubusercontent.com/voltfinance/swap-default-token-list/master/build/voltage-swap-default.tokenlist.json',
  [SupportedChainId.METIS_ANDROMEDA]: 'https://raw.githubusercontent.com/Netswap/token-lists/master/mb.tokenlist.json',
  [SupportedChainId.BSC_MAINNET]: 'https://tokens.pancakeswap.finance/pancakeswap-extended.json',
  [SupportedChainId.XDC_APOTHEM]:
    'https://raw.githubusercontent.com/RomanowAgency/xdc-token-list/master/testnet.tokenlist.json',
  [SupportedChainId.XINFIN]:
    'https://raw.githubusercontent.com/RomanowAgency/xdc-token-list/master/mainnet.tokenlist.json',
}

export const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST]

// this is the default list of lists that are exposed to users
// lower index == higher priority for token import
const DEFAULT_LIST_OF_LISTS_TO_DISPLAY: string[] = [
  UNI_LIST,
  COMPOUND_LIST,
  AAVE_LIST,
  CMC_ALL_LIST,
  COINGECKO_LIST,
  KLEROS_LIST,
  GEMINI_LIST,
  WRAPPED_LIST,
  SET_LIST,
  ROLL_LIST,
  ARBITRUM_LIST,
  OPTIMISM_LIST,
  ...Object.values(NETWORK_SPECIFIC_LISTS),
]

export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...DEFAULT_LIST_OF_LISTS_TO_DISPLAY,
  ...UNSUPPORTED_LIST_URLS, // need to load dynamic unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [...Object.values(NETWORK_SPECIFIC_LISTS)]
